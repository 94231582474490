'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var isLocalHost = function isLocalHost(hostname) {
  return !!(hostname === 'localhost' || hostname === '[::1]' || hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/));
};

var HttpsRedirect = function HttpsRedirect(_ref) {
  var disabled = _ref.disabled,
      children = _ref.children;

  if (!disabled && typeof window !== 'undefined' && window.location && window.location.protocol === 'http:' && !isLocalHost(window.location.hostname)) {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }

  return children;
};

HttpsRedirect.propTypes = {
  children: _propTypes2.default.node,
  disabled: _propTypes2.default.bool
};

exports.default = HttpsRedirect;